import dayjs from '@/lib/dayjs'
import {
  barChartOptions,
  donutChartOptions,
  lineColumnChartOptions,
  columnChartOptions,
  lineChartOptions,
} from '@/config/dashboard'
import { getDateOfRange, getOrgChartData, getOrgShopChartData } from '@/utils/dashboard'
import { getCssVar } from '@/utils/helper'

const dayFormat = (date, dateFormat) => {
  return dayjs(date).format(dateFormat)
}

//  年齡圖表default
export const generateMemberAgeConfig = (labels = [], series = []) => ({
  ...lineColumnChartOptions,
  colors: [
    getCssVar('chart-1'),
    getCssVar('chart-2'),
    getCssVar('chart-3'),
    getCssVar('chart-4'),
  ],
  xaxis: {
    type: 'date',
    tickAmount: 8,
    title: {
      text: '人數',
    },
  },
  yaxis: [
    {
      title: {
        text: '年齡',
      },
    },
  ],
  labels,
  series,
})

const labels = [
  '20以下',
  '21-25',
  '26-30',
  '31-35',
  '36-40',
  '41-45',
  '46-50',
  '51-55',
  '56-60',
  '61-65',
  '65+',
  '其他',
]

const initDataSet = () => {
  return labels.map(() => 0)
}

export const getAgeDataByShop = async ({ orgId, shopId }) => {
  const { result, lastUpdatedAt } = await getOrgShopChartData({
    orgId,
    shopId,
    key: 'memberAgeRate',
  })

  const dataset = initDataSet()

  result.forEach((item) => {
    const ageIndex = labels.findIndex((index) => index === item.age_range)
    dataset[ageIndex] = item.count
  })

  return dataset
}

export const getAgeData = async ({ orgId }) => {
  const { result, lastUpdatedAt } = await getOrgChartData({
    orgId,
    key: 'orgMemberAgeRate',
  })

  const dataset = initDataSet()

  result.forEach((item) => {
    const ageIndex = labels.findIndex((index) => index === item.age_range)
    dataset[ageIndex] = item.count
  })

  const chartConfig = generateMemberAgeConfig(labels, [{ data: dataset }])

  return {
    newOptions: chartConfig,
    lastUpdatedAt,
  }
}

export const generateMemberProportionConfig = (labels = [], series = []) => ({
  ...donutChartOptions,
  colors: [
    getCssVar('chart-1'),
    getCssVar('chart-2'),
    getCssVar('chart-3'),
    getCssVar('chart-4'),
  ],
  series,
  labels,
})

//  新註冊標籤default
export const generateNewMemberTagsConfig = (labels = [], series = []) => ({
  ...lineColumnChartOptions,
  colors: [
    getCssVar('chart-1'),
    getCssVar('chart-2'),
    getCssVar('chart-3'),
    getCssVar('chart-4'),
  ],
  xaxis: {
    type: 'date',
    title: {
      text: '日期',
    },
  },
  yaxis: [
    {
      title: {
        text: '人數',
      },
    },
  ],
  dataLabels: {
    enabled: false,
  },
  labels,
  series,
})

const initNewMemberTabsDataset = (labels) => {
  return labels.map(() => 0)
}

export const getNewMemberTagsData = async ({
  orgId,
  interval,
  splitType,
  currentTags,
  allTags,
}) => {
  const dateFormat = 'YYYY/MM/DD'

  const dateRange = getDateOfRange({
    interval,
    splitType,
  })

  const { result } = await getOrgChartData({
    orgId,
    key: 'newMemberTagTrend',
    dateTrunc: splitType,
    createdAtStart: dateRange.createdAtStart,
    createdAtEnd: dateRange.createdAtEnd,
    tags: currentTags,
  })

  const data = result.map((item) => ({
    ...item,
    createdAt: dayFormat(item.createdAt, dateFormat),
  }))

  const dataset = initNewMemberTabsDataset(dateRange.dateArray)

  const tagsObject = {}

  //  初始化標籤資料 count_tag_ 為api前綴
  currentTags.forEach((item) => {
    tagsObject[`count_tag_${item}`] = [...dataset]
  })

  data.forEach((res) => {
    const dataIndex = dateRange.dateArray.findIndex(
      (item) => item === res.createdAt,
    )
    currentTags.forEach((item) => {
      const keyName = `count_tag_${item}`
      tagsObject[keyName][dataIndex] += res[keyName] || 0
    })
    dataset[dataIndex] = res.count
  })

  const series = [
    {
      name: '新註冊會員',
      type: 'column',
      data: dataset,
    },
  ]

  if (allTags && Object.keys(tagsObject).length) {
    Object.keys(tagsObject).forEach((key) => {
      const matchTag = allTags.find((item) => `count_tag_${item.id}` === key)
      series.push({
        name: matchTag.name,
        type: 'line',
        data: tagsObject[key],
      })
    })
  }

  return generateNewMemberTagsConfig(dateRange.dateArray, series)
}

//  會員輪廓下方長條圖default (首購回購 新舊會員 共用)
export const generateColumnConfig = ({ labels = [], series = [] }) => ({
  ...columnChartOptions,
  colors: [getCssVar('chart-1')],
  xaxis: {
    type: 'date',
    title: {
      text: '標籤',
    },
  },
  yaxis: [
    {
      title: {
        text: '百分比',
      },
    },
  ],
  dataLabels: {
    enabled: false,
  },
  labels,
  series,
})

//  首購回購標籤
export const getFirstPurchaseRateData = async ({
  orgId,
  interval,
  currentTags,
  allTags,
}) => {
  const dateRange = getDateOfRange({
    interval,
    splitType: 'day',
  })

  const labels = currentTags.map((currentTag) => {
    return allTags.find((tag) => {
      return tag.id === currentTag
    })
  })

  const { result } = await getOrgChartData({
    orgId,
    key: 'orgFirstPurchaseRate',
    dateTrunc: 'day',
    createdAtStart: dateRange.createdAtStart,
    createdAtEnd: dateRange.createdAtEnd,
    tags: currentTags,
  })

  const firstPurchase = result.find(
    (item) => item.transaction_status === '首購客',
  )

  const rePurchase = result.find((item) => item.transaction_status === '回購客')

  return {
    firstPurchase: {
      count: firstPurchase?.count || 0,
      labels: labels.map((item) => item.name),
      series: labels.map((item) => {
        return firstPurchase ? firstPurchase[`count_tag_${item.id}`] : 0
      }),
    },
    rePurchase: {
      count: rePurchase?.count || 0,
      labels: labels.map((item) => item.name),
      series: labels.map((item) => {
        return rePurchase ? rePurchase[`count_tag_${item.id}`] : 0
      }),
    },
  }
}

//  新舊會員標籤
export const getNewAndOldMemberData = async ({
  orgId,
  interval,
  currentTags,
  allTags,
}) => {
  const dateRange = getDateOfRange({
    interval,
    splitType: 'day',
  })

  console.log('❓❓❓', dateRange)

  const labels = currentTags.map((currentTag) => {
    return allTags.find((tag) => {
      return tag.id === currentTag
    })
  })

  const { result } = await getOrgChartData({
    orgId,
    key: 'newOldMemberRate',
    dateTrunc: 'day',
    createdAtStart: dateRange.createdAtStart,
    createdAtEnd: dateRange.createdAtEnd,
    tags: currentTags,
  })

  const newMember = result.find((item) => item.new_old_member === '新會員')

  const oldMember = result.find((item) => item.new_old_member === '舊會員')

  return {
    newMember: {
      count: newMember?.count || 0,
      labels: labels.map((item) => item.name),
      series: labels.map((item) => {
        return newMember ? newMember[`count_tag_${item.id}`] : 0
      }),
    },
    oldMember: {
      count: oldMember?.count || 0,
      labels: labels.map((item) => item.name),
      series: labels.map((item) => {
        return oldMember ? oldMember[`count_tag_${item.id}`] : 0
      }),
    },
  }
}
